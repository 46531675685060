<template>
  <div>

    <div class="d-flex w-100">
      <month-picker-input @change="onChangeMonth" :default-month="defaultMonth" />
      <div class="flex-grow-1"></div>
      <b-button variant="outline-primary" class="btn-wishlist" style="padding-top: 14px;" @click="downloadCsv">
        <feather-icon icon="DownloadIcon" class="mr-50" />
        <span>Export</span>
      </b-button>
    </div>
    <div class="mt-2">
      <b-table striped hover :items="logs"></b-table>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <div class="triangle"></div>
      <div class="time-wrapper">
        Total: {{ `${Math.floor(hours / 60)} hours ${hours % 60} minute(s)` }}
      </div>

    </div>

  </div>
  <!-- <div class="app-calendar overflow-hidden border bg-white p-1 flex-1"> -->
  <!-- </div> -->
</template>

<script>
import {
  BButton, BTable
} from 'bootstrap-vue'
import moment from 'moment'
import { MonthPickerInput } from 'vue-month-picker'
import Parse from "parse/dist/parse.min.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { convertMinToHr } from "@core/utils/utils";

export default {
  components: {
    BButton,
    BTable,
    MonthPickerInput
  },
  data() {
    return {
      defaultMonth: new Date().getMonth() + 1,
      hours: 0,
      logs: [],
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  methods: {
    onChangeMonth(date) {
      this.loadData(date)
    },
    convertToMins(hours) {
      if (!hours) {
        return 0
      }

      return parseInt(hours.split(":")[0]) * 60 + parseInt(hours.split(":")[1])
    },
    loadData(date) {
      const Booking = Parse.Object.extend("Booking");
      const query = new Parse.Query(Booking);

      const currentUser = Parse.User.current();
      const Client = Parse.Object.extend("Client");
      const client = new Client();
      client.id = localStorage.getItem("clientId");
      query.equalTo("client", client);

      query.include("assistant")

      query.greaterThanOrEqualTo("start", date.from);
      query.lessThanOrEqualTo("start", date.to);
      // query.exists("time");

      query
        .find()
        .then((results) => {

          const ids = results.map(result => result.id)
          const Log = Parse.Object.extend("Log");
          const logsQuery = new Parse.Query(Log);

          logsQuery.containedIn("booking", ids)
          logsQuery.find().then((logs) => {
            this.hours = 0;
            this.logs = logs.map((log) => {
              const booking = results.find(booking => booking.id === log.attributes.booking.id)
              const duration = moment.duration(moment(booking.attributes.end).diff(moment(booking.attributes.start))).asMinutes();
              this.hours += duration
              return {
                date: `${moment(log.attributes.booking.attributes.start).format("D MMM YYYY")} ${moment(log.attributes.booking.attributes.start).format("H:mm")} - ${moment(log.attributes.booking.attributes.end).format("H:mm")}`,
                assistant: `${booking.attributes.assistant.attributes.firstName} ${booking.attributes.assistant.attributes.lastName}`,
                doctor: log.attributes.doctor,
                description: log.attributes.comments,
                hoursLogged: convertMinToHr(duration ?? 0),
              }
            })
          })
        })
        .catch((ex) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching bookings",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    downloadCsv() {
      let csv = 'Date,Assistant,Doctor,Description,Hours Logged\n';
      this.logs.forEach((item) => {
        csv += `${item.date},${item.assistant},${item.doctor},${item.description},${item.hoursLogged}`
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'timesheet.csv';
      anchor.click();
    }
  }
};
</script>

<style lang="scss">
.month-picker__year {
  button {
    padding-bottom: 41px;
  }
}

.month-picker-input {}

.time-wrapper {
  height: 38px;
  width: 300px;
  background: rgba(115, 103, 240, 0.12);
  padding: 10px;
  font-weight: 700;
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid rgba(115, 103, 240, 0.12);

  border-top: 20px solid rgba(115, 103, 240, 0.12);
  border-bottom: 20px solid transparent
}
</style>
